import React from 'react';
// import './ExternalLink.scss';

const ExternalLink = ({
  href,
  className='',
  children
}) => {
  return (
    <a
      href={href}
      className={`ExternalLink ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

export default ExternalLink ;
