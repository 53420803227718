import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './Header.scss';

import banner from '../assets/banner.png';

const Header = () => {
  const { pathname } = useLocation();
  const highlight = pathname || ''; // hedge out 'undefined'

  console.log(highlight);
  return (
    <header className="Header">
      <div className="navigation">
        <Link
          to='/'
          className={highlight === '/' ? 'current' : ''}
        >
          <div className='vertical-center'>Home</div>
        </Link>

        <Link
          to='/game'
          className={highlight.startsWith('/game') ? 'current' : ''}
        >
          <div className='vertical-center'>Play</div>
        </Link>

        <Link
          to='/connectome-explorer'
          className={highlight.startsWith('/connectome-explorer') ? 'current' : ''}
        >
          <div className='vertical-center'>Connectome Explorer</div>
        </Link>

        <Link
          to='/action-potentials'
          className={highlight.startsWith('/action-potentials') ? 'current' : ''}
        >
          <div className='vertical-center'>Action Potentials</div>
        </Link>
      </div>
      <img src={banner} alt='Crescent Loom banner' />

      {/*highlight !== '/loomapalooza' &&
        <Link to='/loomapalooza' className='temporary-promo' >
          <div>
            ✨ Educators! We're
              running a workshop
            August 16th. Join us!  ✨
          </div>
        </Link>
      */}

    </header>
  );
}

export default Header ;
