import React from 'react';
import './ContentPanel.scss';

const ContentPanel = ({
  className='',
  children
}) => {
  return (
    <div className={`ContentPanel ${className}`}>
      {children}
    </div>
  );
}

export default ContentPanel ;
