import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

import LoadinDots from './shared/LoadinDots.jsx';

import './Main.scss';

import Header from './Header.jsx';
import Footer from './Footer.jsx';

const PageHome = lazy(() => import('./home/PageHome.jsx'));
const PageGame = lazy(() => import('./game/PageGame.jsx'));
const PageConnectome = lazy(() => import('./connectome/PageConnectome.jsx'));
const PageActionPotentials = lazy(() => import('./actionpotentials/PageActionPotentials.jsx'));
const PageActionPotentialGuidebook = lazy(() => import('./actionpotentials/PageActionPotentialGuidebook.jsx'));
const PageValidate = lazy(() => import('./validate/PageValidate.jsx'));
const PageLoomapalooza = lazy(() => import('./loomapalooza/PageLoomapalooza.jsx'));
const PageResources = lazy(() => import('./resources/PageResources.jsx'));
const PageAdmin = lazy(() => import('./admin/PageAdmin.jsx'));
const PageNotFound = lazy(() => import('./notfound/PageNotFound.jsx'));

const DESC_MAIN = "Crescent Loom is an in-browser biology game. Knit bones, stitch muscles, and weave neurons into biologically-realistic underwater lifeforms."
const DESC_CONNECTOME = "A virtual neuroscience wetlab. Use laboratory techniques to unravel the nervous systems of creatures created in Crescent Loom."
const DESC_APS = "A neural simulation showing ion channel dynamics during action potentials in a swimming creature."
const DESC_VALIDATE = "Validate purchased keys to create private online server pools for your creatures."
const DESC_LOOMAPALOOZA = 'Event page for the summer teaching workshop for Crescent Loom.'
const DESC_RESOURCES = 'Activities, worksheets, and videos for teaching neurobiology using Crescent Loom.'

const Layout = ({title, description, children}) => {
  return (
    <>
      <Helmet>
          <title>{`${title} — Crescent Loom`}</title>
          <meta name="description" content={description} />
      </Helmet>

      <Header />

      <Suspense fallback={<LoadinDots />}>
        {children}
        <Outlet />
      </Suspense>

      <Footer />
    </>
  )
}

const Main = () => {
  return (
    <div className='Main'>
      <BrowserRouter>

        <Routes>
          <Route path='/'>
            <Route
              index
              element={
                <Layout title='Home' description={DESC_MAIN}>
                  <PageHome />
                </Layout>
              }
            />

            <Route path="game" element={<Layout title='Play' description={DESC_MAIN} />}>
              <Route index element={<PageGame />} />
            </Route>

            <Route path="connectome-explorer" element={<Layout title='Connectome Explorer' description={DESC_CONNECTOME} />}>
              <Route index element={<PageConnectome />} />
            </Route>

            <Route path="action-potentials" element={<Layout title='Action Potentials' description={DESC_APS} />}>
              <Route index element={<PageActionPotentials />} />
              <Route path="guidebook" element={<PageActionPotentialGuidebook />} />
            </Route>

            <Route path="validate" element={<Layout title='Validate Key' description={DESC_VALIDATE} />}>
              <Route index element={<PageValidate />} />
            </Route>

            <Route path="loomapalooza" element={<Layout title='Loomapalooza' description={DESC_LOOMAPALOOZA} />}>
              <Route index element={<PageLoomapalooza />} />
            </Route>

            <Route path="resources" element={<Layout title='Teaching materials' description={DESC_RESOURCES} />}>
              <Route index element={<PageResources />} />
            </Route>

            <Route path="secret-admin-path-olive-only" element={<Layout title='Admin' description={'Shh. Nobody else is supposed to know about this.'} />}>
              <Route index element={<PageAdmin />} />
            </Route>

            <Route
              path= "*"
              element={
                <Layout title='Not Found' description={'This content has been moved.'}>
                  <PageNotFound />
                </Layout>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}


export default Main ;
