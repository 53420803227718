import React from 'react';
import ContentPanel from './shared/ContentPanel.jsx';
import ExternalLink from './shared/ExternalLink.jsx';

import prideFlag from '../assets/trans_pride.svg';

import './Footer.scss';

const Footer = () => {
  const handleSubmit = (e) => {
    window.open('https://tinyletter.com/wick', 'popupwindow', 'scrollbars=yes,width=800,height=600');
    return true
  }

  return (
    <footer className="Footer">
      <ContentPanel>
        <h3>About</h3>
        <p>
          Crescent Loom is designed and programmed by <ExternalLink href="https://wick.works/about/">Olive Perry</ExternalLink>.

          <ExternalLink href="https://wick.itch.io/aesthetic">
            <img className="pride-flag" src={prideFlag} alt='Pride flag' />
          </ExternalLink>

          Development was initially funded via a <ExternalLink href="https://www.kickstarter.com/projects/wickworks/crescent-loom-stitch-muscles-weave-neurons-create">Kickstarter</ExternalLink> in 2016 and has been supported by their part-time day job since then.
        </p>

        <p>For problems, questions, or requests, please email <code>olive@wick.works</code></p>

        <div className="newsletter">
          <p>Subscribe to the development log here:</p>
          <form action="https://tinyletter.com/wick" method="post" target="popupwindow" onSubmit={handleSubmit}>
    				 <input name="email" id="tlemail" type="text" placeholder="Email address" />
    				 <input value="1" name="embed" type="hidden" />
    				 <input value="Subscribe" id="submitbutton" type="submit" />
    			</form>
        </div>

        <p>
          Smaller changes are announced <ExternalLink href="https://twitter.com/crescentloom"> via Twitter</ExternalLink> and on the <ExternalLink href="https://wick.itch.io/crescent-loom/devlog">itch.io devlog</ExternalLink> as they happen.
        </p>

      </ContentPanel>
    </footer>
  );
}

export default Footer ;
